import React from "react";
import "./environment.css";
import envImg1 from "../../Assets/Images/landing/envImg1.png";
import envImg2 from "../../Assets/Images/landing/envImg2.png";
import envImg3 from "../../Assets/Images/landing/envImg3.png";
import envImg4 from "../../Assets/Images/landing/envImg4.png";
import family from "../../Assets/Images/landing/family.png";
import gradient from "../../Assets/Images/landing/gradient.png";
const Environment = () => {
  return (
    <div className="learning_hub-environment">
      <img
        className="learning_hub-environment_gradientbg"
        src={gradient}
        alt=""
      />
      <p className="learning_hub-environment-heading">
        We give best environment for your beloved child to grow and thrive
      </p>
      <div className="learning_hub-environment_img-div">
        <img src={envImg1} alt="" className="learning_hub-environment_img" />
        <img src={envImg2} alt="" className="learning_hub-environment_img" />
        <img src={envImg3} alt="" className="learning_hub-environment_img" />
        <img src={envImg4} alt="" className="learning_hub-environment_img" />
      </div>
      <img className="learning_hub-environment_familyimg" src={family} alt="" />
      <div className="learning_hub-environment-mission">
        <p className="learning_hub-environment-mission-heading">Our Mission</p>
        <h3>Empowering Children Through Speech Therapy in Noida</h3>
        <p className="learning_hub-environment-mission-content">
          At The Learning Hub Speech Therapy Clinic in Noida, our mission is
          clear: we're committed to understanding the unique needs of every
          special child. Our goal is to enable parents and professionals to help
          children reach their full potential by providing top-quality speech
          therapy and Behavioral Therapy services and globally researched
          products at an affordable cost, enhancing their quality of life.
        </p>
        <br />
        <h3>Join Us on this Colorful Journey</h3>
        <p className="learning_hub-environment-mission-content">
          We look forward to embarking on a colorful journey with you. Together,
          we can ensure that we offer the best speech therapy services in Noida
          to these special, gifted children of God. Let's work together to
          empower them and help them shine.
        </p>
      </div>
    </div>
  );
};

export default Environment;
