import React, { useEffect } from "react";
import "./contact.css";
import LearningHub from "../../Components/LearningHub/LearningHub";
import OperationalHours from "../../Components/Operational Hours/OperationalHours";
import JoinUs from "../../Components/Join Us/JoinUs";
import ContactHero from "../../Components/Hero/ContactHero";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <ContactHero />
      <LearningHub />
      <div className="learning_hub-background">
        <OperationalHours />
        <JoinUs />
      </div>
    </div>
  );
};

export default Contact;
