import React, { useEffect, useRef, useState } from "react";
import "./Feedback.css";
import logo from "../../Assets/Images/feedback/logo.svg";
import blueDots from "../../Assets/Images/feedback/blueDots.svg";

import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

const Feedback = () => {
  const form = useRef();
  const [childName, setChildName] = useState("");
  const [lastName, setLastName] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [therapistName, setTherapistName] = useState("");
  const [userFeedback, setUserFeedback] = useState("");
  const [userRating, setUserRating] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    if (
      childName &&
      lastName &&
      parentEmail &&
      therapistName &&
      userFeedback &&
      userRating
    ) {
      emailjs
        .sendForm(
          "service_atu21jd", //service_id of emailjs
          "template_ff61sry", //template_id of emailjs
          form.current,
          "XmMw-Qk4PTAOjU2Cd" //public_key of emailjs
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      setChildName("");
      setLastName("");
      setParentEmail("");
      setTherapistName("");
      setUserFeedback("");
      setUserRating("");
      alert(
        "Dear Customer, We have received your Feedback. We will contact you shortly."
      );
    } else alert("Please fill all the details");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="learning-hub_feedback">
      <div className="learning-hub_feedback_blue-strip"></div>
      <div className="learning-hub_feedback_header">
        <Link to="/" className="learning-hub_feedback_logo">
          <img src={logo} alt="" />
        </Link>
        <img className="learning-hub_feedback_dots" src={blueDots} alt="" />
      </div>
      <form
        ref={form}
        onSubmit={(e) => sendEmail(e)}
        className="learning-hub_feedback_form"
      >
        <p className="learning-hub_feedback_form_title">
          Parents Feedback Form
        </p>
        <div className="learning-hub_feedback_form_name-last-div">
          <div className="learning-hub_feedback_form_item-div learning-hub_feedback_form_name-div">
            <label className="learning-hub_feedback_form_label">
              Child Name
            </label>
            <input
              value={childName}
              onChange={(e) => setChildName(e.target.value)}
              id="child_name"
              name="child_name"
              className="learning-hub_feedback_form_input"
              type="text"
            />
          </div>
          <div className="learning-hub_feedback_form_item-div learning-hub_feedback_form_name-div">
            <label className="learning-hub_feedback_form_label">
              Last Name
            </label>
            <input
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              id="last_name"
              name="last_name"
              className="learning-hub_feedback_form_input"
              type="text"
            />
          </div>
        </div>
        <div className="learning-hub_feedback_form_item-div">
          <label className="learning-hub_feedback_form_label">
            Parent Email
          </label>
          <input
            value={parentEmail}
            onChange={(e) => setParentEmail(e.target.value)}
            id="parent_email"
            name="parent_email"
            className="learning-hub_feedback_form_input"
            type="text"
          />
        </div>
        <div className="learning-hub_feedback_form_item-div">
          <label className="learning-hub_feedback_form_label">
            Therapist Name
          </label>
          <input
            value={therapistName}
            onChange={(e) => setTherapistName(e.target.value)}
            id="therapist_name"
            name="therapist_name"
            className="learning-hub_feedback_form_input"
            type="text"
          />
        </div>
        <div className="learning-hub_feedback_form_item-div">
          <label className="learning-hub_feedback_form_label">Feedback</label>
          <textarea
            onChange={(e) => setUserFeedback(e.target.value)}
            value={userFeedback}
            id="user_feedback"
            name="user_feedback"
            rows={4}
            className="learning-hub_feedback_form_input"
            type="text"
          />
        </div>
        <div className="learning-hub_feedback_form_item-div">
          <label className="learning-hub_feedback_form_label">Rating</label>
          <div className="learning-hub_feedback_form_ratings-input-div">
            <input
              onChange={(e) => setUserRating(e.target.value)}
              value={userRating}
              id="user_rating"
              name="user_rating"
              className="learning-hub_feedback_form_user-rating-input"
              type="text"
            />
            <div className="learning-hub_feedback_form_ratings-div">
              <button type="button" onClick={() => setUserRating("😔")}>
                😔
              </button>
              <button type="button" onClick={() => setUserRating("😑")}>
                😑
              </button>
              <button type="button" onClick={() => setUserRating("🙂")}>
                🙂
              </button>
              <button type="button" onClick={() => setUserRating("😊")}>
                😊
              </button>
              <button type="button" onClick={() => setUserRating("😍")}>
                😍
              </button>
            </div>
          </div>
        </div>
        <button className="learning-hub_feedback_form_btn" type="submit">
          Submit
        </button>
      </form>

      <div className="learning-hub_feedback_footer">
        <img className="learning-hub_feedback_dots" src={blueDots} alt="" />
      </div>
      <p className="learning-hub_feedback_footer_text">
        Your Feedback is valuable to us.
      </p>
      <div className="learning-hub_feedback_blue-strip-footer"></div>
    </div>
  );
};

export default Feedback;
