import React from "react";
import stars from "../../Assets/Images/landing/stars.png";
import "./customer.css";

const Customer = () => {
  return (
    <div className="learning_hub-customer">
      <div className="learning_hub-heading-rating-group">
        <p className="learning_hub-heading">What our customers say about us</p>
        <div className="learning_hub-heading-ratings">
          <p>4.9 </p>
          <img
            className="learning_hub-heading-ratings_stars"
            src={stars}
            alt=""
          />
          <p>100 Google reviews</p>
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://goo.gl/maps/V9bXhSgzZZZN5NZH7"
          className="learning_hub-customer_btn"
        >
          See Google Reviews
        </a>
      </div>
    </div>
  );
};

export default Customer;
