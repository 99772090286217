import React from "react";
import "./learninghub.css";
const LearningHub = () => {
  return (
    <div className="learning_hub-learning-hub-main">
      <p className="learning_hub-learning-hub_heading">The Learning Hub</p>
      <div className="learning_hub-learning-hub_content">
        <div className="learning_hub-learning-hub_map">
          <iframe
            title="mainMap"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.426801697383!2d77.436344914278!3d28.4968040969635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce7a6799b7341%3A0x6581e0e1f8975505!2sThe%20Learning%20Hub!5e0!3m2!1sen!2sin!4v1674122839881!5m2!1sen!2sin"
            width="100%"
            height="100%"
            className="learning_hub-learning-hub_map-main"
            loading="lazy"
          />
        </div>
        <div className="learning_hub-learning-hub_branches">
          <p className="learning_hub-learning-hub_branches-heading">
            Head Office
          </p>
          <div className="learning_hub-learning-hub_head-branch">
            <div className="learning_hub-learning-hub_branch-heading-div">
              <p className="learning_hub-learning-hub_branch-heading">Noida</p>
              <p className="learning_hub-learning-hub_branch-view-on-map">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://goo.gl/maps/Lj8gMxFpHzzByzFVA">
                  View on map
                </a>
              </p>
            </div>
            <p className="learning_hub-learning-hub_branch-address">
              The Learning Hub
              <br />
              PK 52, behind Gulshan Dynasty, Sector 144, Noida, Uttar Pradesh
              201306
            </p>
          </div>
          <p className="learning_hub-learning-hub_branches-heading">
            Our Branches
          </p>
          <div className="learning_hub-learning-hub_branch-div">
            <div className="learning_hub-learning-hub_branch">
              <div className="learning_hub-learning-hub_branch-heading-div">
                <p className="learning_hub-learning-hub_branch-heading">
                  INDIRAPURAM
                </p>
                <p className="learning_hub-learning-hub_branch-view-on-map">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://goo.gl/maps/Lj8gMxFpHzzByzFVA">
                    View on map
                  </a>
                </p>
              </div>
              <p className="learning_hub-learning-hub_branch-address">
                The Learning Hub
                <br />
                Plot No- 238 (Basement), Shiv Shakti Street, Niti Khand-1,
                Indirapuram, Gaziabad
              </p>
            </div>
            <div className="learning_hub-learning-hub_branch">
              <div className="learning_hub-learning-hub_branch-heading-div">
                <p className="learning_hub-learning-hub_branch-heading">
                  NOIDA EXTENSION
                </p>
                <p className="learning_hub-learning-hub_branch-view-on-map">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://goo.gl/maps/Lj8gMxFpHzzByzFVA">
                    View on map
                  </a>
                </p>
              </div>
              <p>
                The Learning Hub
                <br />
                G-249, 11th Avenue, Gaur City-2, Opposite to Mahagun Mart,
                Greater Noida West
              </p>
            </div>
            <div className="learning_hub-learning-hub_branch">
              <div className="learning_hub-learning-hub_branch-heading-div">
                <p className="learning_hub-learning-hub_branch-heading">
                  GREATER NOIDA
                </p>
                <p className="learning_hub-learning-hub_branch-view-on-map">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://goo.gl/maps/Lj8gMxFpHzzByzFVA">
                    View on map
                  </a>
                </p>
              </div>
              <p>
                The Learning Hub
                <br />
                House no. 242, Block C, Sigma II, Greater Noida, Uttar Pradesh-
                201310
              </p>
            </div>
            <div className="learning_hub-learning-hub_branch">
              <div className="learning_hub-learning-hub_branch-heading-div">
                <p className="learning_hub-learning-hub_branch-heading">
                  GURGAON
                </p>
                <p className="learning_hub-learning-hub_branch-view-on-map">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://goo.gl/maps/Lj8gMxFpHzzByzFVA">
                    View on map
                  </a>
                </p>
              </div>
              <p>
                The Learning Hub
                <br />
                Sector - 82, Gurgaon
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningHub;
