import React from "react";
import "./footer.css";
import logo from "../../Assets/Images/logo.png";
import fb from "../../Assets/Images/social/fb.png";
import ig from "../../Assets/Images/social/ig.png";
import lkdin from "../../Assets/Images/social/lkdin.png";
import { Link } from "react-router-dom";
import QRCode from "qrcode.react";

const Footer = () => {
  return (
    <div className="learning_hub-footer_main">
      <img
        onClick={() => window.scrollTo(0, 0)}
        className="learning_hub-footer_logo"
        src={logo}
        alt=""
      />
      <p className="learning_hub-footer_title">Follow us on:</p>
      <div className="learning_hub-footer_social">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/thelearninghubnoida/"
        >
          <img className="learning_hub-footer_social" src={fb} alt="" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://instagram.com/thelearninghubnoida?igshid=YmMyMTA2M2Y="
        >
          <img className="learning_hub-footer_social" src={ig} alt="" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://in.linkedin.com/in/shishu-pal-437562105"
        >
          <img src={lkdin} alt="" />
        </a>
      </div>
      <Link className="learning_hub-footer_feedback-btn" to="/feedback">
        Feedback
      </Link>
      <QRCode
        value="https://thelearninghubnoida.in/feedback"
        style={{ marginInline: "auto", padding:"2rem" }}
        size={200}
      />
      <p className="learning_hub-footer_text">
        Copyright 2022 The Learning Hub- All Rights Reserved
      </p>
    </div>
  );
};

export default Footer;
