import React from "react";
import "./offer.css";
const Offer = () => {
  return (
    <div className="learning_hub-offer">
      <p className="learning_hub-offer-heading">What we Offer</p>
      <div className="learning_hub-offer-main">
        <div className="learning_hub-offer-card1">
          <p className="learning_hub-offer-card-content">
            OCCUPATIONAL THERAPY
          </p>
        </div>
        <div className="learning_hub-offer-card2">
          <p className="learning_hub-offer-card-content">SPEECH THERAPY</p>
        </div>
        <div className="learning_hub-offer-card3">
          <p className="learning_hub-offer-card-content">SPECIAL EDUCATION</p>
        </div>
        <div className="learning_hub-offer-card2">
          <p className="learning_hub-offer-card-content">ACTIVITY AREA</p>
        </div>
        <div className="learning_hub-offer-card3">
          <p className="learning_hub-offer-card-content">GROUP CLASS</p>
        </div>
        <div className="learning_hub-offer-card1">
          <p className="learning_hub-offer-card-content">BEHAVIOUR THERAPY</p>
        </div>
      </div>
    </div>
  );
};

export default Offer;
