import React, { useEffect } from "react";
import Appointment from "../../Components/Appointment/Appointment";
import HappyStudents from "../../Components/Happy Students/HappyStudents";
import AboutHero from "../../Components/Hero/AboutHero";
const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <Hero id="2" /> */}
      <AboutHero />
      <HappyStudents />
      <Appointment />
    </div>
  );
};

export default Contact;
