import React from "react";
import "./operationalhours.css";
import whatsapp from "../../Assets/Images/contact/whatsapp.png";

const OperationalHours = () => {
  return (
    <div className="learning_hub-operational-hours">
      <p className="learning_hub-operational-hours-heading">
        Operational Hours
      </p>
      <div className="learning_hub-operational-hours-main">
        <div className="learning_hub-operational-hours-section1">
          <p className="learning_hub-operational-hours-section1-content">
            Monday- Friday
          </p>
          <p className="learning_hub-operational-hours-section1-content">
            9:00am - 9:00pm
          </p>
        </div>
        <div className="learning_hub-operational-hours-section2">
          <p className="learning_hub-operational-hours-section2-content">
            Saturday
          </p>
          <p className="learning_hub-operational-hours-section2-content">
            9:00am - 9:00pm
          </p>
        </div>
        <div className="learning_hub-operational-hours-section3">
          <p className="learning_hub-operational-hours-section3-content">
            Sunday
          </p>
          <p className="learning_hub-operational-hours-section3-content">
            On availability
          </p>
        </div>
      </div>
      <div className="learning_hub-operational-hours_appointment">
        <p className="learning_hub-operational-hours-phone">
          Book your Appointment : 9718441836
        </p>
        <p className="learning_hub-operational-hours-email">
          Email : thelearninghubnoida@yahoo.com
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://api.whatsapp.com/send?phone=919718441836">
          <img
            className="learning_hub-operational-hours_whatsapp"
            src={whatsapp}
            alt=""
          />
        </a>
      </div>
    </div>
  );
};

export default OperationalHours;
