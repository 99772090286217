import React from "react";
import "./appointment.css";
import whatsapp from "../../Assets/Images/contact/whatsapp.png";
import kids from "../../Assets/Images/appointment/kids.png";
const Appointment = () => {
  return (
    <div className="learning_hub-appointment-main">
      <img className="learning_hub-appointment-kidsImg" src={kids} alt="" />
      <div className="learning_hub-appointment-content">
        <p className="learning_hub-phone-heading">
          Book your Appointment : 9718441836
        </p>
        <p className="learning_hub-email-heading">
          Email : thelearninghubnoida@yahoo.com
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://api.whatsapp.com/send?phone=919718441836">
          <img src={whatsapp} alt="" />
        </a>
      </div>
    </div>
  );
};

export default Appointment;
