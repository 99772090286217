import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./navbar.css";
import { GoThreeBars } from "react-icons/go";
import { BsXLg } from "react-icons/bs";
import logo from "../../Assets/Images/logo.png";
import { IoIosArrowDown } from "react-icons/io";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="learning-hub__navbar">
      <div className="learning-hub__navbar-links">
        <div className="learning-hub__navbar-links_logo_container">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="learning-hub__navbar-links_container">
          <li className="learning-hub__navbar-links_li">
            <NavLink to="/">Home</NavLink>
          </li>
          <div className="learning-hub_blog_dropdown">
            <li className="learning-hub__navbar-links_li">
              Blog
              <IoIosArrowDown style={{ fontSize: "1rem" }} />
            </li>
            <div className="learning-hub_blog_dropdown-content">
              <li className="learning-hub_blog_navbar-links_li">
                <NavLink to="/blog/occupational-therapy">
                  Occupational Therapy
                </NavLink>
              </li>
              <li className="learning-hub_blog_navbar-links_li">
                <NavLink to="/blog/special-education">
                  Special Education
                </NavLink>
              </li>
              <li className="learning-hub_blog_navbar-links_li">
                <NavLink to="/blog/speech-therapy">Speech Therapy</NavLink>
              </li>
              <li className="learning-hub_blog_navbar-links_li">
                <NavLink to="/blog/behavior-analysis">
                  Behavior Analysis
                </NavLink>
              </li>
            </div>
          </div>
          <li className="learning-hub__navbar-links_li">
            <NavLink to="/about-us">About Us</NavLink>
          </li>
          <li
            className="learning-hub__navbar-links_contact-btn"
            onClick={() => setToggleMenu(false)}
          >
            <NavLink to="/contact-us">Contact Us</NavLink>
          </li>
        </div>
      </div>
      <div className="learning-hub__navbar-menu">
        {toggleMenu ? (
          <BsXLg
            className="learning-hub__navbar-menu_toggleBtn"
            color="var(--color-text)"
            size={20}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <GoThreeBars
            className="learning-hub__navbar-menu_toggleBtn"
            color="var(--color-text)"
            size={25}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="learning-hub__navbar-menu_container">
            <div className="learning-hub__navbar-menu_container-links">
              <li onClick={() => setToggleMenu(false)}>
                <NavLink to="/">Home</NavLink>
              </li>
              <div className="learning-hub_blog_dropdown">
                <li className="learning-hub__navbar-menu_li">
                  Blog
                  <IoIosArrowDown style={{ fontSize: "1rem" }} />
                </li>
                <div className="learning-hub_blog_dropdown-content">
                  <li className="learning-hub_blog_navbar-links_li">
                    <NavLink
                      to="/blog/occupational-therapy"
                      onClick={() => setToggleMenu(false)}
                    >
                      Occupational Therapy
                    </NavLink>
                  </li>
                  <li
                    className="learning-hub_blog_navbar-links_li"
                    onClick={() => setToggleMenu(false)}
                  >
                    <NavLink to="/blog/special-education">
                      Special Education
                    </NavLink>
                  </li>
                  <li
                    className="learning-hub_blog_navbar-links_li"
                    onClick={() => setToggleMenu(false)}
                  >
                    <NavLink to="/blog/speech-therapy">Speech Therapy</NavLink>
                  </li>
                  <li
                    className="learning-hub_blog_navbar-links_li"
                    onClick={() => setToggleMenu(false)}
                  >
                    <NavLink to="/blog/behavior-analysis">
                      Behavior Analysis
                    </NavLink>
                  </li>
                </div>
              </div>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink to="/about-us">About Us</NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink to="/contact-us">Contact Us</NavLink>
              </li>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
