import React, { useRef } from "react";
import "./hero.css";
import contactHeroBg from "../../Assets/Images/hero/contactHeroBg.png";
import emailjs from "@emailjs/browser";
import contactImg from "../../Assets/Images/hero/contactImg.png";

const ContactHero = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if (
      document.getElementById("user_name").value &&
      document.getElementById("user_email").value &&
      document.getElementById("user_phone").value &&
      document.getElementById("user_msg").value
    ) {
      emailjs
        .sendForm(
          "service_atu21jd", //service_id of emailjs
          "template_s0ir45n", //template_id of emailjs
          form.current,
          "XmMw-Qk4PTAOjU2Cd" //public_key of emailjs
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      document.getElementById("user_name").value = "";
      document.getElementById("user_email").value = "";
      document.getElementById("user_phone").value = "";
      document.getElementById("user_msg").value = "";
      alert(
        "Dear Customer, We have received your message. We will contact you shortly."
      );
    } else alert("Please fill all the details");
  };
  return (
    <div>
      <div className="learning_hub-hero_main">
        <img
          className="learning_hub-hero_contactbg"
          src={contactHeroBg}
          alt=""
        />
        <div className="learning_hub-hero_text-section">
          <p className="learning_hub-hero_heading1">CONTACT US</p>
          <div className="learning_hub-hero_aboutOwner_mobileview">
            <img
              className="learning_hub-hero_img_contact"
              src={contactImg}
              alt=""
            />
          </div>
          <p className="learning_hub-hero_content1">
            Please contact us directly with any questions, comments or
            scheduling inquiries you may have.
          </p>
          <div className="learning_hub-hero_content2">
            <form
              ref={form}
              onSubmit={sendEmail}
              className="learning_hub-hero_contact-form"
            >
              <input
                type="text"
                id="user_name"
                name="user_name"
                placeholder="Name"
              />
              <input
                type="text"
                id="user_email"
                name="user_email"
                placeholder="Email "
              />
              <input
                type="text"
                id="user_phone"
                name="user_phone"
                placeholder="Phone Number"
              />
              <textarea
                id="user_msg"
                rows={4}
                placeholder="Message"
                name="message"
              />
              <button
                type="submit"
                value="Send"
                className="learning_hub_hero-contact-button"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
        <div className="learning_hub-hero_contactimg-div">
          <img
            className="learning_hub-hero_img_contact"
            src={contactImg}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default ContactHero;
