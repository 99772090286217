import landingImg from "../Assets/Images/hero/landingImg.png";

const HeroData = [
  {
    id: "1",
    heading1: "Meet Mr. Shishu Pal",
    heading2: "Your Expert Special Educator",
    heading3: "and Behavioral Therapist",
    heading4: "Why Choose Mr. Shishu Pal for Your Child's Development?",
    content1:
      "Are you searching for top-notch speech therapy and inclusive education support for your child? Look no further! Mr. Shishu Pal, a dedicated Special Educator and Behavioral Therapist, is here to empower your child's journey towards effective communication and emotional understanding.",
    content2:
      "With over 9 years of experience within the NCR's inclusive school system, Mr. Shishu Pal has honed his expertise in fostering inclusive education. His 'whole language' curriculum encompasses a holistic approach, covering:",
    point1: "- Receptive and expressive language development",
    point2: "- Decoding social cues",
    point3: "- Understanding emotions essential for children",
    img: (
      <img className="learning_hub-hero_img_landing" src={landingImg} alt="" />
    ),
    button: "Book an Appointment",
    name: "Mr. Shishu Pal",
    position: "Special Educator & Behavioural Therapist",
  },
  // {
  //   id: "2",
  //   heading1: "About The Learning Hub",
  //   heading2: "",
  //   content1:
  //     "At The Learning Hub, we are dedicated to providing a safe and inclusive learning environment for students with disabilities or special educational need Our team of highly trained and compassionate educators is committed to helping each child their full potential, both academically and personally.",
  //   content2:
  //     "Our mission is to understand the unique needs of every special child and strive to enable parents and professionals to help children grow to their full potential by providing them with the best service and of globally researched products at an affordable cost to enhance their quality of life. We remain dedicated to give back to the community not only with our products and services, but also by using a portion of our profit to sponsor a variety of events to benefit people with special needs and learning disabilities.Looking forward to a fun filled, colourful journey together by ensuring we can offer the best of services to these special gifted children of God.",
  //   img: <img className="learning_hub-hero_img_about" src={aboutImg} alt="" />,
  //   button: "Book an Appointment",
  // },
];
export default HeroData;
