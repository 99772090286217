import React from "react";
import "./happystudents.css";
import img1 from "../../Assets/Images/happy students/img1.png";
import img2 from "../../Assets/Images/happy students/img2.png";
import img3 from "../../Assets/Images/happy students/img3.png";
import img4 from "../../Assets/Images/happy students/img4.png";
import img5 from "../../Assets/Images/happy students/img5.png";
import pinkFlower from "../../Assets/Images/happy students/pinkFlower.png";
import yellowFlower from "../../Assets/Images/happy students/yellowFlower.png";
const HappyStudents = () => {
  return (
    <div className="learning_hub-happy-students">
      <p className="learning_hub-happy-students-heading">Our Happy Students</p>
      <div className="learning_hub-happy-students-img-group">
        <img src={img1} className="learning_hub-happy-students-img1" alt="" />
        <img src={img2} className="learning_hub-happy-students-img2" alt="" />
        <img src={img3} className="learning_hub-happy-students-img3" alt="" />
        <img src={img4} className="learning_hub-happy-students-img4" alt="" />
        <img src={img5} className="learning_hub-happy-students-img5" alt="" />
      </div>
      <div className="learning_hub-happy-students-family">
        <div className="learning_hub-happy-students-family-inner">
          <img
            className="learning_hub-happy-students-family-pinkFlower"
            src={pinkFlower}
            alt=""
          />
          <p className="learning_hub-happy-students-family-text">One Family</p>
          <img
            className="learning_hub-happy-students-family-yellowFlower"
            src={yellowFlower}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default HappyStudents;
