import React from "react";
import "./hero.css";
import heroBg from "../../Assets/Images/hero/heroBg.png";

import aboutImg from "../../Assets/Images/hero/aboutImg.png";

const AboutHero = (props) => {
  return (
    <div>
      <div className="learning_hub-hero_main">
        <img className="learning_hub-hero_bg" src={heroBg} alt="" />
        <div className="learning_hub-hero_text-section">
          <p className="learning_hub-hero_heading1">
            About <br /> The Learning Hub
          </p>
          <h3 className="learning_hub-hero_heading2">
            Your Partner in Speech Therapy <br />& Behavioral Therapy in Noida
          </h3>
          <div className="learning_hub-hero_aboutOwner_mobileview">
            <img
              className="learning_hub-hero_img_about"
              src={aboutImg}
              alt=""
            />
          </div>
          <p className="learning_hub-hero_content1">
            Welcome to The Learning Hub, your trusted source for speech therapy
            and inclusive education services in Noida. We are dedicated to
            creating a safe and inclusive learning environment for students with
            disabilities or special educational needs.
          </p>
          <p className="learning_hub-hero_heading2">Our Commitment</p>
          <div className="learning_hub-hero_content2">
            Our team consists of highly trained and compassionate educators who
            are committed to helping each child reach their full potential, both
            academically and personally. At The Learning Hub, we understand the
            unique needs of every special child.
          </div>
          <p className="learning_hub-hero_heading2">
            Empowering Children <br /> Through Speech Therapy
          </p>
          <div className="learning_hub-hero_content2">
            Our mission is to empower parents and professionals to help children
            grow to their full potential. We achieve this by providing top-notch
            speech therapy services and globally researched products at an
            affordable cost, enhancing the quality of life for every child we
            serve.
            <br />
            Join The Learning Hub today and make a difference in your child's
            life.
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send?phone=919718441836"
          >
            <button className="learning_hub_hero-button">
              Book an Appointment
            </button>
          </a>
        </div>
        <div className="learning_hub-hero_img-div">
          <img className="learning_hub-hero_img_about" src={aboutImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
