import React from "react";
import "./hero.css";
import heroBg from "../../Assets/Images/hero/heroBg.png";
import HeroData from "../../Data/HeroData";

const Hero = (props) => {
  return (
    <div>
      {HeroData.map((item) =>
        item.id === props.id ? (
          <div key={item.id} className="learning_hub-hero_main">
            <img className="learning_hub-hero_bg" src={heroBg} alt="" />
            <div className="learning_hub-hero_text-section">
              <p className="learning_hub-hero_heading1">{item.heading1}</p>
              <p className="learning_hub-hero_heading2">{item.heading2}</p>
              <p className="learning_hub-hero_heading2">{item.heading3}</p>
              <div className="learning_hub-hero_aboutOwner_mobileview">
                {item.img}
                <div className="learning_hub-hero_aboutOwner_mobileview-text">
                  <p className="learning_hub-hero_name_mobile">{item.name}</p>
                  <p className="learning_hub-position_mobile">
                    {item.position}
                  </p>
                </div>
              </div>
              <p className="learning_hub-hero_content1">{item.content1}</p>
              <h1 className="learning_hub-hero_content1">{item.heading4}</h1>
              <div className="learning_hub-hero_content2">{item.content2}</div>
              <p className="learning_hub-hero_point">{item.point1}</p>
              <p className="learning_hub-hero_point">{item.point2}</p>
              <p className="learning_hub-hero_point">{item.point3}</p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://api.whatsapp.com/send?phone=919718441836"
              >
                <button className="learning_hub_hero-button">
                  {item.button}
                </button>
              </a>
            </div>
            <div className="learning_hub-hero_img-div">
              {item.img}
              <p className="learning_hub-hero_name">{item.name}</p>
              <p className="learning_hub-position">{item.position}</p>
            </div>
          </div>
        ) : (
          ""
        )
      )}
    </div>
  );
};

export default Hero;
