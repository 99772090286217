import React from "react";
import "./App.css";

import Navbar from "./Common/Navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./Pages/Contact/Contact";
import Footer from "./Common/Footer/Footer";
import About from "./Pages/About/About";
import LandingPage from "./Pages/LandingPage/LandingPage";
import OccupationalTherapy from "./Pages/Blog/OccupationalTherapy";
import SpecialEducation from "./Pages/Blog/SpecialEducation";
import SpeechTherapy from "./Pages/Blog/SpeechTherapy";
import BehaviorAnalysis from "./Pages/Blog/BehaviorAnalysis";
import Feedback from "./Components/Feedback/Feedback";

import { Outlet } from "react-router-dom";

const NavbarFooterLayout = () => (
  <>
    <Navbar />
    <Outlet />
    <Footer />
  </>
);

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NavbarFooterLayout />}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route
            path="/blog/occupational-therapy"
            element={<OccupationalTherapy />}
          />
          <Route
            path="/blog/special-education"
            element={<SpecialEducation />}
          />
          <Route path="/blog/speech-therapy" element={<SpeechTherapy />} />
          <Route
            path="/blog/behavior-analysis"
            element={<BehaviorAnalysis />}
          />
        </Route>
        <Route path="/feedback" element={<Feedback />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
