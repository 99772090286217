import React, { useRef, useState } from "react";

import "./joinus.css";

// import emailjs from "@emailjs/browser";
import axios from "axios";

const JoinUs = () => {
  const [file, setFile] = useState("Please upload your resume");
  const form = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [resume, setResume] = useState("");

  const formdata = new FormData();
  formdata.append("name", name);
  formdata.append("email", email);
  formdata.append("phone", phone);
  formdata.append("resume", resume);

  const [loading, setLoading] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);

    // const res = await axios.post("http://127.0.0.1:8000/send-email", formdata);
    const res = await axios.post(
      "https://server.thelearninghubnoida.in/send-email",
      formdata
    );
    if (res.data.result === "Success") {
      alert(res.data.message);
      setLoading(false);
      setName("");
      setEmail("");
      setPhone("");
    } else alert("Error! please try again");
  };

  return (
    <div className="learning_hub-join_main">
      <p className="learning_hub-join-us-heading">
        Want to join us?
        <br />
        <span className="learning_hub-join-us-subheading">
          Submit your resume
        </span>
      </p>
      <form
        onSubmit={sendEmail}
        ref={form}
        className="learning_hub-join-us-form"
      >
        <input
          className="learning_hub-join-us_form-input"
          type="text"
          placeholder="Name*"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          className="learning_hub-join-us_form-input"
          type="text"
          placeholder="Email*"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="learning_hub-join-us_form-input"
          type="text"
          placeholder="Phone*"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <div className="learning_hub-join-us_file-input-div">
          <label
            htmlFor="user_file"
            className="learning_hub-join-us_file-input-btn"
          >
            Attach Resume*
          </label>
          <input
            type="file"
            onChange={(e) => {
              setFile(e.target?.files[0]?.name);
              setResume(e.target.files[0]);
            }}
            accept=".pdf"
            id="user_file"
          />
          <p className="learning_hub-join-us_filename">{file}</p>
        </div>
        <button type="submit" value="Send" className="learning_hub_join-button">
          {loading ? "Submitting . . ." : "Submit Profile"}
        </button>
      </form>
    </div>
  );
};

export default JoinUs;
