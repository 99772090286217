import React, { useEffect } from "react";
import Hero from "../../Components/Hero/Hero";
import Environment from "../../Components/Environment/Environment";
import Offer from "../../Components/Offer/Offer";
import Customer from "../../Components/Customer/Customer";
import Appointment from "../../Components/Appointment/Appointment";
import WhyChooseUs from "../../Components/WhyChooseUs/WhyChooseUs";
const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Hero id="1" />
      <WhyChooseUs />
      <Environment />
      <Offer />
      <Customer />
      <Appointment />
    </div>
  );
};

export default LandingPage;
