import React from "react";
import "./whyChooseUs.css";

const WhyChooseUs = () => {
  return (
    <div className="learning_hub-why-us">
      <div className="learning_hub-why-us-mission">
        <p className="learning_hub-why-us-mission-heading">
          Credentials That Inspire Confidence
        </p>
        <p className="learning_hub-why-us-mission-content">
          Mr. Shishu Pal is a graduate in B.A Psychology from Allahabad
          University and holds a D.Ed in Special Education from the Institute of
          Rehabilitation, Sahibabad. His collaborative approach involves working
          closely with parents, administrators, co-teachers, paraprofessionals,
          and external agencies to ensure that children of all abilities receive
          the support they need.
        </p>
        <br />
        <br />
        <p className="learning_hub-why-us-mission-heading">
          Transforming Mainstream Education
        </p>
        <p className="learning_hub-why-us-mission-content">
          Mr. Shishu Pal's expertise extends to working with children in
          mainstream classes. His mission is not only to enhance their reading,
          writing, and spelling abilities but also to boost their self-esteem.
          He achieves this through:
        </p>
        <p className="learning_hub-why-us-mission-content">
          - Behavior modification techniques
        </p>
        <p className="learning_hub-why-us-mission-content">
          - Problem-solving skill development
        </p>
        <p className="learning_hub-why-us-mission-content">
          - Integration of functional living skills
        </p>
        <br />
        <br />

        <p className="learning_hub-why-us-mission-heading">
          Empowering Educators
        </p>
        <p className="learning_hub-why-us-mission-content">
          Mr. Shishu Pal is not just an educator; he's also a mentor to other
          teachers. He organizes and conducts teacher training programs aimed at
          empowering conventional educators to understand and effectively teach
          children with Dyslexia and Learning Difficulties.
        </p>
        <p className="learning_hub-why-us-mission-content">
          Unlock Your Child's Full Potential with Mr. Shishu Pal's Expertise.
        </p>
        {/* <p className="learning_hub-why-us-mission-content">
          [Contact Mr. Shishu Pal](#Watsapp link) for specialized speech therapy
          and inclusive education support today.
        </p> */}
      </div>
    </div>
  );
};

export default WhyChooseUs;
